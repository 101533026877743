
import mailAnimate from '../../assets/images/mail.gif';
import { AdminService } from '../../core/services/AdminService';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

export default function MailSent(props: { idActivation: string, idUser: number }) {
  const { idActivation, idUser } = props;
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const sendMail = () => {
    if (loadingSendMail) {
      return;
    }
    AdminService.getInstance().resentMail(idActivation, idUser).then((res) => {
      enqueueSnackbar({
        variant: 'success',
        message: "Se volio a enviar el correo",
        autoHideDuration: 5000
      });
      setLoadingSendMail(false);
    }, () => {
      enqueueSnackbar({
        variant: 'error',
        message: "Hubo un error al enviar el correo",
        autoHideDuration: 5000
      });
      setLoadingSendMail(false);
      //console.log('error', error);
    })
  }
  return (

    <div className="text-center">

      <div className='text-primary font-bold text-2xl lg:text-4xl '>
        ¡Correo enviado!  <img src={mailAnimate} alt="" className="w-10 inline-block lg:w-16" />
      </div>
      <div className="my-3 text-xl lg:text-2xl lg:my-5">
        ¡Por favor revisa tu correo electrónico para completar el registro!
      </div>
      <div className="text-xs mb-2 lg:text-sm lg:mb-3">
        Si no te llegó el correo vuelve a intentarlo, si persiste el error comunicate con soporte
      </div>
      <div>
        <button className="btn" onClick={sendMail}>Volver a enviar</button>
      </div>
    </div>
  );
}
