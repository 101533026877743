

import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router-dom";
import ActivationObserved from "./components/pages/ActivationObserved";
import Home from './components/pages/Home';
import NoPage from "./components/pages/NoPage";
import Register from './components/pages/Register';
import { configrationApp } from "./core/config";
import { AxiosSetup } from "./core/setups/AxiosSetup";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: configrationApp().azureClientId,
    redirectUri: configrationApp().azureRedirectUri,
  }
};
console.log('msalConfig', msalConfig.auth);

const msalInstance = new PublicClientApplication(msalConfig);

AxiosSetup.getInstance().activeResponseInterceptor();
function App() {
  console.log('enter app');


  return (
    <div>
      <SnackbarProvider />
      <MsalProvider instance={msalInstance} >
        <Routes>
          <Route path='/' index element={<Home msal={msalInstance} />} />

          <Route path="/register/:idActivation/:idUser" element={<Register />} />
          <Route path="/activation-observed/:type" element={<ActivationObserved />} />
          <Route path="*" element={<NoPage />} />

        </Routes >
      </MsalProvider>
    </div>


  );
}

export default App;
