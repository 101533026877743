import { Navigate, useParams } from 'react-router';
import bg404 from '../../assets/images/bg_404.jpg';
import { configrationApp } from '../../core/config';
export enum TypeObservation {
  Complete = 'Complete',
  Defeated = 'Defeated'
}
interface DataPage {
  title: string;
  subTitle: string;
  message: string;
  url: string;
  nameButton: string;
}
const config: Map<TypeObservation, DataPage> = new Map([
  [TypeObservation.Complete, {
    title: 'Cuenta Activa',
    subTitle: 'Ya el link fue utilizado para activar la cuenta',
    message: 'Te invitamos a ingresar a la aplicación',
    url: configrationApp().urlLogin,
    nameButton: 'Ingresar a Acelera'
  }],
  [TypeObservation.Defeated, {
    title: 'Link caducado',
    subTitle: 'Solo tuviste 24h para usar este link',
    message: 'Te invitamos volver a registrarte',
    url: "/",
    nameButton: 'Ir a la página de inicio '
  }]
])

export default function ActivationObserved() {
  const { type } = useParams() as any;
  if (!type) {
    return <Navigate to="/404" />
  }

  const data = config.get(type as TypeObservation) || config.get(TypeObservation.Defeated) as DataPage;

  return (
    <div className="container relative m-auto">
      <img src={bg404} className="absolute top-0 left-0 w-full h-full object-contain object-bottom" alt='fondo' />
      <div className=" min-h-screen flex items-center justify-start relative z-10 ">
        <div>
          <h1 className=" text-8xl mb-6 font-black">
            {data.title}
          </h1>
          <h2 className="font-medium text-3xl mb-6">
            {data.subTitle}   </h2>
          <div className="max-w-lg mb-6 text-2xl ">
            {data.message}   </div>
          <a href={data.url} className="btn btn-primary btn--lg inline-block px-10" rel="noreferrer">
            {data.nameButton}     </a>
        </div>
      </div>
    </div>
  );
}
