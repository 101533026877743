import { useState } from 'react';
import { useForm } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import FadeLoader from "react-spinners/ClipLoader";
import { LIST_COMPANY_SIZE, LIST_JOBS } from "../../../core/config";

export interface FormInputsRegisterStep2 {
  organization: string;
  position: string;
  organizationSize: string;
}
export default function RegisterStep2(props: { onComplete?: Function }) {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputsRegisterStep2>();
  const [inLoading, setLoading] = useState(false);
  const submitCreateAccount = (data: FormInputsRegisterStep2) => {
    setLoading(true);

    props.onComplete && props.onComplete(data);
  }
  const sizeOptions = LIST_COMPANY_SIZE.map(size => {
    return (
      <option key={size} value={size}>{size}</option>
    )
  });
  const jobOptions = LIST_JOBS.map(job => {
    return (
      <option key={job} value={job}>{job}</option>
    )
  });
  return (
    <form onSubmit={handleSubmit((data) => submitCreateAccount(data))}>
      <div className="mb-3">
        <label htmlFor="organization">Organización</label>
        <input type="text" className='form-input' placeholder='Nombre de tu organización' id="organization"
          autoComplete="organization"
          {...register('organization', {
            required: {
              value: true,
              message: 'El nombre de la organización es requerido'
            }, minLength: {
              value: 2,
              message: "El nombre de la organización debe tener al menos 2 dígitos"
            }
          })} />
        {
          errors.organization && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.organization.message}
              </span>
            </div>
          )
        }
      </div>
      <div className="mb-3">
        <label htmlFor="position">Cargo</label>
        <select id="position" className="form-select" {...register('position', {
          required: {
            value: true,
            message: 'El cargo es requerido'
          }
        })}>
          <option value="">Selecciona tu cargo actual</option>
          {jobOptions}
        </select>
        {
          errors.position && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.position.message}
              </span>
            </div>
          )
        }
      </div>
      <div className="mb-3">
        <label htmlFor="organizationSize">Tamaño</label>
        <select id="organizationSize" className="form-select" {...register('organizationSize', {
          required: {
            value: true,
            message: 'El tamaño es requerido'
          }
        })}>
          <option value="">Seleccione el tamaño de la organización</option>
          {sizeOptions}
        </select>
        {
          errors.organizationSize && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.organizationSize.message}
              </span>
            </div>
          )
        }
      </div>
      <div>
        <input type="checkbox" name="" id="" className='mr-2' required

        />
        Acepto los <a href="https://acelera.io/es/politica-privacidad/" rel="noreferrer" target='_blank' className='underline'>terminos y condiciones</a>
      </div>

      <div className="mt-10" >
        <button type='submit' className='btn btn--block flex justify-center items-center' >
          {
            inLoading ? <FadeLoader color="#fff" size={14} className="mr-2" /> : null
          }
          Crear Cuenta
        </button>
      </div>
    </form>
  )
}