import axios from "axios";
import { configrationApp } from "../config";
import { ResponseRegisterMailDTO, ResponseValidateActivationDTO } from "../dtos";
import { FormInputsRegister } from "../../components/pages/Register";


//let instance: AdminService;
export class AdminService {
  private readonly BASE_API = configrationApp().apiAdmin;
  private static instance: AdminService;

  constructor() {
    if (AdminService.instance) {
      throw new Error("You can only create one instance!");
    }
    AdminService.instance = this;
  }

  static getInstance() {
    if (!AdminService.instance) {
      AdminService.instance = new AdminService();
    }

    return AdminService.instance;
  }
  public isAvaibleUserActivation(idActivation: string, idUser: number): Promise<ResponseValidateActivationDTO> {
    const url = `${this.BASE_API}/onboarding/avaible/${idActivation}/${idUser}`;
    return axios.get(url);
  }
  public completeRegister(data: FormInputsRegister, idActivation: string, idUser: number) {
    const url = `${this.BASE_API}/onboarding/complete-register/${idActivation}/${idUser}`;
    return axios.post(url, data);
  }
  public registerEmail(email: string): Promise<ResponseRegisterMailDTO> {
    const url = `${this.BASE_API}/onboarding`;
    /* eslint-disable no-restricted-globals */
    const domain = (location as any).host;
    return axios.post(url, { email, domain });
  }
  public resentMail(idActivation: string, idUser: number): Promise<void> {
    const url = `${this.BASE_API}/onboarding/resent-mail`;
    /* eslint-disable no-restricted-globals */
    const domain = (location as any).host;
    return axios.post(url, {
      id: idActivation, idUser, domain
    });
  }
}