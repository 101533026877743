import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { AdminService } from '../../core/services/AdminService';
import RegisterStep1, { FormInputsRegisterStep1 } from './sections/registerStep1';
import RegisterStep2, { FormInputsRegisterStep2 } from './sections/registerStep2';
import { TypeObservation } from './ActivationObserved';
let hasValidation = false;
export interface FormInputsRegister extends FormInputsRegisterStep1, FormInputsRegisterStep2 {

}
export default function Register() {
  const navigate = useNavigate();

  const { idActivation, idUser } = useParams();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [dataForm, setDataForm] = useState({
    name: '',
    lastName: '',
    telephone: '',
    organization: '',
    position: '',
    organizationSize: '',
  });
  const completeStep1 = (info: FormInputsRegisterStep1) => {
    setDataForm({
      ...dataForm,
      ...info
    });
    setStep(2);
  }
  const completeStep2 = (info: FormInputsRegisterStep2) => {
    const dataFinal = {
      ...dataForm,
      ...info
    }
    setDataForm(dataFinal);
    if (idActivation && idUser) {
      AdminService.getInstance().completeRegister(dataFinal, idActivation as string, +idUser).then(res => {
        const { access_token, tenant } = res as any;
        const { urlLogin } = window as any;
        const url = `${urlLogin}/auto-login/${access_token}/${tenant}/true`;
        console.log('url', url);

        window.open(url, '_top');
      }).catch(error => {
        console.log('error', error);

      })
    }
  }
  async function validate() {
    hasValidation = true;
    if (idActivation && idUser) {
      try {
        const res = await AdminService.getInstance().isAvaibleUserActivation(idActivation as string, +idUser);
        const { isComplete, expired } = res;
        console.log('res', res);

        let type: TypeObservation | undefined;
        if (isComplete) {
          type = TypeObservation.Complete;

        } else if (expired) {
          type = TypeObservation.Defeated;
        }
        if (type) {
          const url = `/activation-observed/${type}`;
          navigate(url);
        } else {

          if (res.user?.email) {
            console.log('ress', res.user.email);
            setEmail(res.user.email);
          }
        }
      } catch (error) {
        const type = TypeObservation.Defeated;
        const url = `/activation-observed/${type}`;
        navigate(url);
      }
    }

  }
  useEffect(() => {
    if (!hasValidation) {
      validate();
    }
  });


  const classBarSteps = 'flex gap-4 mb-6 ' + (step === 1 ? '' : ' flex-row-reverse');

  return (
    <div className='min-h-screen grid content-center justify-center p-4 '>
      <div className='max-w-lg'>
        <picture className='flex justify-center mb-2'>
          <img src={logo} alt="Logo Acelera" />
        </picture>
        <h1 className='text-2xl font-medium text-center mb-6 leading-none'>
          Bienvenido a ACELERA <br />
          <small className='font-normal text-sm'>{email}</small>
        </h1>

        <div className='text-center mb-4 text-sm'>
          {
            step === 1 ? "Personaliza tu experiencia en dos simples pasos" : "Sólo dos pasos nos ayudarán a personalizar su experiencia."
          }

        </div>

        <div className='w-[303px] m-[auto]'>
          <div className={classBarSteps}>
            <div className='h-3 w-6/12 bg-primary rounded' />
            <div className='h-3 w-6/12 bg-secondary rounded' />

          </div>
          {
            step === 1 ? <RegisterStep1 onComplete={completeStep1} /> : <RegisterStep2 onComplete={completeStep2} />
          }
        </div>
      </div>
    </div>
  )
}