import Contacts from "./parts/Contacts";



export default function Footer() {
    return (

        <div className=" text-center">
            <div className="my-4 mb-8">
                <Contacts />
            </div>

            <div className="text-xs mb-2">
                USA   |   Chile   |   Perú   |   México   |    Colombia   |    Bolivia
            </div>
            <div className="text-[10px]">
                Términos y condiciones | Pzrivacidad | Copyright © 2022 ACELERA. All rights reserved.
            </div>
        </div>
    )
}
