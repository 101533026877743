import bg404 from '../../assets/images/bg_404.jpg'


export default function NoPage() {

    return (
        <div className="container relative m-auto">
            <img src={bg404} className="absolute top-0 left-0 w-full h-full object-contain object-bottom" alt='fondo' />
            <div className=" min-h-screen flex items-center justify-start relative z-10 ">
                <div>
                    <h1 className=" text-8xl mb-6 font-black">
                        404
                    </h1>
                    <h2 className="font-medium text-3xl mb-6">
                        Llegaste a un lugar inhabitado, continúa tu viaje...      </h2>
                    <div className="max-w-lg mb-6 text-2xl ">
                        Lo sentimos, parece que no podemos encontrar la página increíble que estás buscando.      </div>
                    <a href="/" className="btn btn-primary btn--lg inline-block px-10" rel="noreferrer">
                        Ir a la página de inicio      </a>
                </div>
            </div>
        </div>
    );
}
