
import { AiOutlineMail, AiOutlineWhatsApp } from "react-icons/ai";



export default function Contacts(props: { inline?: boolean }) {

    const direction = props.inline ? 'flex-row gap-1' : 'flex-col';
    const classParent = `flex  items-center justify-center text-sm ${direction}`;
    return (
        <div className={classParent}>
            <a className="flex items-center " href="https://api.whatsapp.com/send?phone=13058517931&text=%C2%A1Hola%20Acelera!%20Tengo%20una%20consulta%20o%20incidencia%20para%20ti:"><AiOutlineWhatsApp className="mr-1" /> 1 (305) 851-7931</a>
            <a className="flex items-center" href="mailto:support@acelera.io"> <AiOutlineMail className="mr-1" />soporte@acelera.io</a>
        </div>
    );
}
