import React from "react";

export const configrationApp = () => {
  const { googleClientId, apiAdmin, apiApp, urlLogin, azureClientId, azureRedirectUri } = window as any;
  return {
    googleClientId,
    azureClientId,
    urlLogin,
    apiAdmin,
    apiApp,
    azureRedirectUri
  }
}
export const customStylesModalBase: { content: React.CSSProperties, overlay: React.CSSProperties } = {
  overlay: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0.77) 100%), lightgray 50% / cover no-repeat',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    maxWidth: 600,
    height: 'fit-content',
    borderRadius: 16,
    padding: '2rem',
    transform: 'translate(-50%, -50%)',
  },
};
export const LIST_COMPANY_SIZE = [
  "Más de 10001 empleados",
  "5001 - 10000 empleado",
  "1001 - 5000 empleado",
  "501 - 1000 empleados",
  "201 - 500 empleados",
  "51 - 200 empleados",
  "11 - 50 empleados",
  "2 - 10 empleados",
  "1 empleado",
];
export const LIST_JOBS = [
  "Directorio",
  "CEO",
  "C-Level",
  "Gerente de Finanzas",
  "Gerente de Personas",
  "Gerente de Transformación",
  "Gerente",
  "Líder de equipo",
  "Miembro de equipo",

]

