import axios from "axios";

export class AxiosSetup {
  private static instance: AxiosSetup;
  constructor() {
    if (AxiosSetup.instance) {
      throw new Error("You can only create one instance!");
    }
    AxiosSetup.instance = this;
  }

  static getInstance() {
    if (!AxiosSetup.instance) {
      AxiosSetup.instance = new AxiosSetup();
    }

    return AxiosSetup.instance;
  }
  activeResponseInterceptor() {
    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response.data;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
  }
}