
import { configrationApp } from "../../core/config";



export default function UserExists() {
  const { urlLogin } = configrationApp();

  return (

    <div className="">
      <div className='text-lg text-center mb-2'>
        El correo ya esta registrado.
      </div>
      <a className='btn btn--block' rel="noreferrer" href={urlLogin} target="_top" >
        Ir al login
      </a>
    </div>
  );
}
