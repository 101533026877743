import { useForm } from "react-hook-form";
import { configrationApp } from "../../../core/config";
import { AiOutlineInfoCircle } from "react-icons/ai";

export interface FormInputsRegisterStep1 {
  name: string;
  lastName: string;
  telephone: string;
}
export default function RegisterStep1(props: { onComplete: Function }) {

  const { urlLogin } = configrationApp();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormInputsRegisterStep1>();

  const submitCreateAccount = (data: FormInputsRegisterStep1) => {


    props.onComplete(data);
  }
  return (
    <form onSubmit={handleSubmit((data) => submitCreateAccount(data))}>

      <div className="mb-3">
        <label htmlFor="nameForm">Nombre</label>
        <input type="text" className='form-input' placeholder='Ingresa tu nombre' autoComplete="given-name"
          id="nameForm"
          {...register('name', {

            required: {
              value: true,
              message: 'El nombres es requerido'
            }, minLength: {
              value: 2,
              message: "El nombre debe tener al menos 2 dígitos"
            }
          })}
        />
        {
          errors.name && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.name.message}
              </span>
            </div>
          )
        }
      </div>
      <div className="mb-3">
        <label htmlFor="lastName">Apellido</label>
        <input type="text" className='form-input' placeholder='Ingresa tu apellido' id="lastName"
          autoComplete="family-name"
          {...register('lastName', {
            required: {
              value: true,
              message: 'El apellido es requerido'
            }, minLength: {
              value: 2,
              message: "El apellido debe tener al menos 2 dígitos"
            }
          })}
        />
        {
          errors.lastName && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.lastName.message}
              </span>
            </div>
          )
        }
      </div>
      <div className="mb-3">
        <label htmlFor="telephone">Telefono</label>
        <input type="tel" className='form-input' placeholder='Ingresa tu teléfono'
          id="telephone"
          pattern="[0-9]*"
          onKeyDown={(event) => {
            console.log('evnt', event.code);

            if (!/[0-9]/.test(event.key) && event.code !== 'Backspace') {
              event.preventDefault();
            }
          }}
          {...register('telephone', {
            required: {
              value: true,
              message: 'El teléfono es requerido'
            }, pattern: {
              value: /^[0-9]*$/,
              message: 'No es un télefono valido'
            }
          })}
        />
        {
          errors.telephone && (
            <div className='section-error'>
              <AiOutlineInfoCircle />
              <span className='pl-1'>
                {errors.telephone.message}
              </span>
            </div>
          )
        }
      </div>

      <div>
        <div className='mt-4'>
          <button type='submit' className='btn btn--block'>
            Continuar
          </button>
        </div>

        <div className='text-center mt-4' >
          <a href={urlLogin} className='text-primary'>
            Iniciar sesión con otra cuenta
          </a>
        </div>
      </div>
    </form>
  )
}